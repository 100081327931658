import { Routes } from '@angular/router';
import { User } from '@cat-ai-us-fe/api';
import { RoleGuard } from '@cat-ai-us-fe/shared/data-access';

export const TRAINING_ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        canActivate: [RoleGuard],
        data: {
          roles: [User.RoleEnum.Supervisor],
        },
        loadComponent: () =>
          import('@cat-ai-us-fe/trainings/feature').then(
            (m) => m.TrainingsListViewComponent,
          ),
      },
      {
        path: 'personal',
        loadComponent: () =>
          import('@cat-ai-us-fe/trainings/feature').then(
            (m) => m.PersonalTrainingsViewComponent,
          ),
      },
      {
        path: 'participants',
        loadComponent: () =>
          import('@cat-ai-us-fe/trainings/feature').then(
            (m) => m.ParticipantsManagementViewComponent,
          ),
      },
      {
        path: 'builder',
        data: { fullScreen: true },
        loadComponent: () =>
          import('@cat-ai-us-fe/trainings/feature').then(
            (m) => m.BuildTrainingViewComponent,
          ),
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('@cat-ai-us-fe/trainings/feature').then(
            (m) => m.TrainingsDasboardViewComponent,
          ),
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('@cat-ai-us-fe/trainings/feature').then(
                (m) => m.TrainingDetailsComponent,
              ),
          },
          {
            path: 'materials/:materialId',
            loadComponent: () =>
              import('@cat-ai-us-fe/trainings/feature').then(
                (m) => m.MaterialViewComponent,
              ),
          },
          {
            path: 'builder',
            data: { fullScreen: true },
            loadComponent: () =>
              import('@cat-ai-us-fe/trainings/feature').then(
                (m) => m.BuildTrainingViewComponent,
              ),
          },
          {
            path: 'pass-quiz',
            data: { fullScreen: true },
            loadComponent: () =>
              import('@cat-ai-us-fe/trainings/feature').then(
                (m) => m.PassQuizViewComponent,
              ),
          },
          {
            path: 'certificate/:certificateUuid',
            loadComponent: () =>
              import('@cat-ai-us-fe/trainings/feature').then(
                (m) => m.CertificateViewComponent,
              ),
          },
          {
            path: 'reports',
            children: [
              {
                path: 'member',
                children: [
                  {
                    path: ':memberId',
                    loadComponent: () =>
                      import('@cat-ai-us-fe/trainings/feature').then(
                        (m) => m.ReportDocumentViewComponent,
                      ),
                  },
                ],
              },
              {
                path: ':groupId',
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import('@cat-ai-us-fe/trainings/feature').then(
                        (m) => m.ReportDocumentViewComponent,
                      ),
                  },
                  {
                    path: 'list',
                    loadComponent: () =>
                      import('@cat-ai-us-fe/trainings/feature').then(
                        (m) => m.ReportsListViewComponent,
                      ),
                  },
                  {
                    path: ':memberId',
                    loadComponent: () =>
                      import('@cat-ai-us-fe/trainings/feature').then(
                        (m) => m.ReportDocumentViewComponent,
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
];
